import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// import arrow1 from "../assets/img/arrow1.svg";
// import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from '../assets/img/color-sharp.png';
import html5 from '../assets/img/html5.svg';
import css3 from '../assets/img/css3.svg';
import js from '../assets/img/square-js.svg';
import react from '../assets/img/react.svg';
import bootstrap from '../assets/img/bootstrap.svg';
import sass from '../assets/img/sass.svg';
import git from '../assets/img/git.svg';
import npm from '../assets/img/npm.svg';

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className='skill' id='skills'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='skill-bx wow zoomIn'>
              <h2>Skills</h2>
              <p>
                In my skills section, I highlight a range of technical skills
                that I have developed over the course of my coding bootcamp
                journey. Some of my key technical skills include proficiency in
                React and React Native. I am constantly seeking opportunities to
                grow and improve my abilities.
              </p>
              <Carousel
                responsive={responsive}
                infinite={true}
                className='owl-carousel owl-theme skill-slider'
              >
                <div className='item'>
                  <img src={html5} alt='html' className='skills' />
                  <h5>HTML</h5>
                </div>
                <div className='item'>
                  <img src={css3} alt='css' className='skills' />
                  <h5>CSS</h5>
                </div>
                <div className='item '>
                  <img src={js} alt='javascript' className='skills' />
                  <h5>Javascript</h5>
                </div>
                <div className='item '>
                  <img src={react} alt='react' className='skills' />
                  <h5>React</h5>
                </div>
                <div className='item '>
                  <img src={bootstrap} alt='bootstrap' className='skills' />
                  <h5>Bootstrap</h5>
                </div>
                <div className='item '>
                  <img src={sass} alt='sass' className='skills' />
                  <h5>Sass</h5>
                </div>
                <div className='item '>
                  <img src={git} alt='git' className='skills' />
                  <h5>Git</h5>
                </div>
                <div className='item '>
                  <img src={npm} alt='git' className='skills' />
                  <h5>Npm</h5>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img
        className='background-image-left'
        src={colorSharp}
        alt='background-color'
      />
    </section>
  );
};

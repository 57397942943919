import { Col } from 'react-bootstrap';

export const ProjectCard = ({
  title1,
  title2,
  title3,
  title4,
  description,
  imgUrl,
  link,
}) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className='proj-imgbx'>
        <img src={imgUrl} alt='project' />
        <div className='proj-txtx'>
          <a
            href='https://tic-tac-toe-modified.netlify.app/'
            target='_blank'
            rel='noopener noreferrer'
            className='link'
          >
            <h4>{title1}</h4>
          </a>
          <a
            href='https://todo-list-modified.netlify.app/'
            target='_blank'
            rel='noopener noreferrer'
            className='link'
          >
            <h4>{title2}</h4>
          </a>
          <a
            href='https://random-quote-generator-modified.netlify.app/'
            target='_blank'
            rel='noopener noreferrer'
            className='link'
          >
            <h4>{title3}</h4>
          </a>
          <a
            href='https://amazon-clone-modified.netlify.app/'
            target='_blank'
            rel='noopener noreferrer'
            className='link'
          >
            <h4>{title4}</h4>
          </a>
          <span>{description}</span>
        </div>
      </div>
    </Col>
  );
};

import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import { ProjectCard } from './ProjectCard';
import projImg1 from '../assets/img/tic-tac-toe-modified-netlify-app.png';
import projImg2 from '../assets/img/todo-list-modified-netlify-app.png';
import projImg3 from '../assets/img/random-quote-generator-modified-netlify-app.png';
import projImg4 from '../assets/img/amazon-clone-modified-netlify-app.png';
// import colorSharp2 from '../assets/img/color-sharp2.png';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import underConstruction from '../assets/img/under-construction.jpg';


export const Projects = () => {
  const smallProjects = [
    {
      title1: 'Tic Tac Toe',
      description: '(Modified)',
      imgUrl: projImg1,
    },
    {
      title2: 'Todo List',
      description: '(Modified)',
      imgUrl: projImg2,
    },
    {
      title3: 'Random Quote Generator',
      description: '(Modified)',
      imgUrl: projImg3,
    },
  ];

  const bigProjects = [
    {
      title4: 'Amazon Clone',
      description: '(Modified)',
      imgUrl: projImg4,
    }
  ];

  const personalProjects = [
    {
      imgUrl: underConstruction,
    },
  ];

  return (
    <section className='project' id='projects'>
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? 'animate__animated animate__fadeIn' : ''
                  }
                >
                  <h2>Projects</h2>
                  <p>
                    These projects demonstrate my skills, abilities, and
                    creativity, and provide concrete examples of my work.
                  </p>
                  <Tab.Container id='projects-tabs' defaultActiveKey='first'>
                    <Nav
                      variant='pills'
                      className='nav-pills mb-5 justify-content-center align-items-center'
                      id='pills-tab'
                    >
                      <Nav.Item>
                        <Nav.Link eventKey='first'>Small Projects</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey='second'>Big Projects</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey='third'>Personal Projects</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content
                      id='slideInUp'
                      className={
                        isVisible ? 'animate__animated animate__slideInUp' : ''
                      }
                    >
                      <Tab.Pane eventKey='first'>
                        <Row>
                          {smallProjects.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey='second'>
                        <Row className='amazon-clone'>
                          {bigProjects.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey='third'>
                        <Row className='amazon-clone'>
                          {personalProjects.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      {/* <img className='background-image-right' src={colorSharp2}></img> */}
    </section>
  );
};

import { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import contactImg from '../assets/img/get-in-touch.jpg';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import axios from 'axios';


export const Contact = () => {
  const formInitialDetails = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  };
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Send');
  const [status, setStatus] = useState({});

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setButtonText('Sending...');

    try {
      await axios.post(
        'https://api.sendgrid.com/v3/mail/send',
        {
          personalizations: [
            {
              to: [
                {
                  email: 'mmm.markhil@gmail.com',
                },
              ],
              subject: 'New message from your portfolio website',
            },
          ],
          from: {
            email: formDetails.email,
            name: `${formDetails.firstName} ${formDetails.lastName}`,
            phone: formDetails.phone,
          },
          content: [
            {
              type: 'text/plain',
              value: formDetails.message,
            },
          ],
        },
        {
          headers: {
            Authorization:
              'Bearer API_KEY',
          },
        }
      );

      setStatus({
        message: 'Thank you for your message!',
        type: 'success',
      });
      setButtonText('Send');
      setFormDetails(formInitialDetails);
    } catch (error) {
      setStatus({
        message: 'There was an error sending your message. Please try again.',
        type: 'error',
      });
      setButtonText('Send');
    }
    
  };


  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setButtonText('Sending...');
  //   let response = await fetch('http://localhost:3000/contact', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json;charset=utf-8',
  //     },
  //     body: JSON.stringify(formDetails),
  //   });
  //   setButtonText('Send');
  //   let result = await response.json();
  //   setFormDetails(formInitialDetails);
  //   if (result.code === 200) {
  //     setStatus({ success: true, message: 'Message sent successfully' });
  //   } else {
  //     setStatus({
  //       success: false,
  //       message: 'Something went wrong, please try again later.',
  //     });
  //   }
  // };

  return (
    <section className='contact' id='connect'>
      <Container>
        <Row className='align-items-center'>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) => (
                <img
                  className={
                    isVisible ? 'animate__animated animate__zoomIn' : ''
                  }
                  src={contactImg}
                  alt='Contact Us'
                />
              )}
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? 'animate__animated animate__fadeIn' : ''
                  }
                >
                  <h2>Get In Touch</h2>
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col size={12} sm={6} className='px-1'>
                        <input
                          type='text'
                          value={formDetails.firstName}
                          placeholder='First Name'
                          onChange={(e) =>
                            onFormUpdate('firstName', e.target.value)
                          }
                        />
                      </Col>
                      <Col size={12} sm={6} className='px-1'>
                        <input
                          type='text'
                          value={formDetails.lastName}
                          placeholder='Last Name'
                          onChange={(e) =>
                            onFormUpdate('lastName', e.target.value)
                          }
                        />
                      </Col>
                      <Col size={12} sm={6} className='px-1'>
                        <input
                          type='email'
                          value={formDetails.email}
                          placeholder='Email Address'
                          onChange={(e) =>
                            onFormUpdate('email', e.target.value)
                          }
                        />
                      </Col>
                      <Col size={12} sm={6} className='px-1'>
                        <input
                          type='tel'
                          value={formDetails.phone}
                          placeholder='Phone No.'
                          onChange={(e) =>
                            onFormUpdate('phone', e.target.value)
                          }
                        />
                      </Col>
                      <Col size={12} className='px-1'>
                        <textarea
                          rows='6'
                          value={formDetails.message}
                          placeholder='Message'
                          onChange={(e) =>
                            onFormUpdate('message', e.target.value)
                          }
                        ></textarea>
                        <button type='submit'>
                          <span>{buttonText}</span>
                        </button>
                      </Col>
                      {status.message && (
                        <Col>
                          <p
                            className={
                              status.success === false ? 'success' : 'danger'
                            }
                          >
                            {status.message}
                          </p>
                        </Col>
                      )}
                    </Row>
                  </form>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

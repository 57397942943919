import { useState, useEffect } from 'react';
import { Col, Row, Alert } from 'react-bootstrap';

export const Newsletter = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (status === 'success') clearFields();
  }, [status]);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   email &&
  //     email.indexOf('@') > -1 &&
  //     onValidated({
  //       EMAIL: email,
  //     });
  // };

  const clearFields = () => {
    setEmail('');
  };

  return (
    <Col lg={12}>
      <div className='newsletter-bx wow slideInUp'>
        <Row>
          <Col lg={12} md={6} xl={5}>
            <h3>
              Subscribe to our Newsletter<br></br> & Never miss latest updates
            </h3>
            {status === 'sending' && <Alert>Sending...</Alert>}
            {status === 'error' && <Alert variant='danger'>{message}</Alert>}
            {status === 'success' && <Alert variant='success'>{message}</Alert>}
          </Col>
          <Col md={6} xl={7}>
            <form action='https://formspree.io/f/mpzeaqeo' method='POST'>
              <div className='new-email-bx'>
                <input
                  value={email}
                  type='email'
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder='Email Address'
                  name='_replyto'
                  required
                />
                <div className='button'>
                  <button type='submit'>Submit</button>
                </div>
              </div>
            </form>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

import { Container, Row, Col } from 'react-bootstrap';
import { MailchimpForm } from './MailchimpForm';
import linkedIn from '../assets/img/linkedin.png';
import gmail from '../assets/img/gmail.png';
import github from '../assets/img/github.png';
import { useState } from 'react';

export const Footer = () => {
  const [year] = useState(new Date().getFullYear());

  return (
    <footer className='footer'>
      <Container>
        <Row className='align-items-center'>
          <MailchimpForm />
          <Col size={12} sm={6} className='text-center text-sm-start'>
            <div className='social-icon'>
              <a
                href='https://www.linkedin.com/in/markhil-moreno-330509193'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={linkedIn} alt='linkedIn' />
              </a>
              <a
                href='mailto:mmm.markhil@gmail.com'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={gmail} alt='gmail' />
              </a>
              <a
                href='https://github.com/markhil-22'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={github} alt='github' />
              </a>
            </div>
            <p>Copyright {year}. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

import { Container, Row, Col } from "react-bootstrap"
import react from '../assets/certificates/Dynamic_with_React-React_Course_Certificate1.jpg';
import bootstrap from '../assets/certificates/Front_End_Web_UI_Frameworks_and_Tools_Bootstrap_4-Bootstrap_Course_Certificate.jpg';
import fundamentals from '../assets/certificates/HTML,_CSS_and_JavaScript-HTML,_CSS_and_JavaScript_Course_Certificate.jpg';
import reactNative from '../assets/certificates/Front_End_Web_UI_Frameworks_and_Tools_Bootstrap_4-React_Native_Course_Certificate_18051.jpg';

export const Certificates = () => {
  return (
    <section className='certificate' id='certificates'>
      <Container>
        <h2>Educational Certificates</h2>
        <Col className='m-5'>
          <Row className='align-items-center'>
            <Col size={12} md={6} className='mb-5'>
              <img src={fundamentals} alt='fundamentals' />
            </Col>
            <Col size={12} md={6} className='mb-5'>
              <img src={bootstrap} alt='bootstrap' />
            </Col>
          </Row>
        </Col>
        <Col className='m-5'>
          <Row className='align-items-center'>
            <Col size={12} md={6} className='mb-5'>
              <img src={react} alt='react' />
            </Col>
            <Col size={12} md={6} className='mb-5'>
              <img src={reactNative} alt='react-native' />
            </Col>
          </Row>
        </Col>
      </Container>
    </section>
  );
}

